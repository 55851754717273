<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">关于我们</div>
      <div class="con"><span>【CSGO5E】于20222年成立，CSGO5E是国内货涵盖全面的潮流盲盒购买平台。CSGO5E平台上，你可以通过即时购买盲盒获得</span><br>
<span>更多潮物商品CSGO5E致力于全网最火最新最全的潮物潮玩市场打造，实时更新内容，为用户提供了更丰富的欧皇开箱开箱体验，<br></span>
<span>使用独特的玩法 更便捷更高效的交易模式，促使CSGO5E盲盒内商品更快捷安全的选取到用户手上，被众多盲盒爱好者所喜爱。</span><br>
<!-- <span>联系地址：301-309 Nathan Road, Yau Tsim Mong, Hong Kong</span><br>
<span>联系方式：www.gosking.net@163.com</span><br> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt{
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    background-color: #1a1c24;

    .doubt-warp{
        width: 1200px;
        margin: 0 auto;
    }
    .title{
        padding: 20px 0;
        font-size: 24px;
        color: #848492;
    }
    .con{
      font-size: 14px;
      color: #848492;
      span{
        display: inline-block;
       margin-top: 10px;
      }
    }
}
</style>
